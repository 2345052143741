.guest-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &.header-fixed {

    .flash-text {
      margin-bottom: 60px;
    }

    header {
      position: fixed;
      z-index: 99999;
      top: 0px;
      width: 100%;
      left: 0px;
    }
  }

  .flash-text {
    background: black;
    color: white;
    font-size: 14px;
    line-height: 16px;
    padding: 8px;
    text-align: center;
  }

  header {
    display: flex;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    height: 59px;
    background: white;

    .menu {
      z-index: 9999;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &>.cta {
        cursor: pointer;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 30px;
        }
      }

      .popin {
        position: absolute;
        top: 90%;
        width: 250px;
        left: 10px;
        background: white;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.17);
        pointer-events: auto;

        .links {

          &> * + * {
            border-top: 1px solid #D9D9D9;
          }

          .item {
            display: block;
            padding: 10px;
            cursor: pointer;
            color: black;
            text-decoration: none;

            &:hover {
              background: #F9F9F9;
            }

            &.list {
              padding: 0px;

              .cta {
                padding: 10px;
                text-align: left;
              }

              .sub {
                border-top: 1px solid #D9D9D9;
                padding-left: 10px;

                &> * + * {
                  border-top: 1px solid #D9D9D9;
                }

                a {
                  display: block;
                  padding: 10px;
                  cursor: pointer;
                  color: black;
                  text-decoration: none;

                  &:hover {
                    background: white;
                  }
                }
              }
            }
          }
        }

        .socials {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0px;
          border-top: 1px solid #D9D9D9;

          a {
            display: block;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;

            &:hover {
              background: #F9F9F9;
            }

            img {
              width: 25px;
            }
          }
        }

        .tel {
          display: none;
          position: relative;
          z-index: 2;
          align-items: center;
          padding: 20px 0px;
          color: black;
          text-decoration: none;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #D9D9D9;
          border-left: none;

          img {
            width: 15px;
            margin-top: -10px;
            margin-bottom: -6px;
            margin-right: 5px;
          }
        }
      }
    }

    .logo {
      position: relative;
      z-index: 1;
      position: absolute;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 59px;
        max-width: 180px;
      }
    }

    .space {
      flex: 1;
      position: relative;
      z-index: 0;
    }

    .tel {
      position: relative;
      z-index: 2;
      border-left: 1px solid #e7e7e7;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      color: black;
      text-decoration: none;

      img {
        width: 20px;
        margin-top: -10px;
        margin-bottom: -6px;
        margin-right: 5px;
      }
    }

    .basket {
      position: relative;
      z-index: 2;
      background: #f6f6f9;
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 0px 10px;
      position: relative;

      .icon {
        width: 30px;
        margin-top: -10px;
        margin-bottom: -6px;
        margin-right: 5px;
        margin-left: 10px;
      }

      .popin {
        position: absolute;
        top: 90%;
        width: 400px;
        right: 10px;
        background: white;
        border-radius: 5px;
        overflow: hidden;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.17);
        pointer-events: auto;

        .empty {
          color: grey;
          font-size: 1.4em;
          font-weight: 900;
          text-align: center;
          padding: 50px 0px;
        }

        .lines {
          border-radius: 5px 5px 0px 0px;
          border-bottom: none;
          max-height: 500px;
          overflow-y: auto;

          .line {
            padding: 20px;
            display: flex;
            align-items: center;

            img {
              width: 40px;
              margin-right: 20px;
            }

            .text {
              flex: 1;
            }

            .price {
              font-weight: bold;
            }

            .remove {
              padding: 10px;
              border-radius: 4px;
              cursor: pointer;

              &:hover {
                background: #f9b3b3;
                color: red;
              }
            }
          }
        }

        .go {
          box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.15);
          border-radius: 0px 0px 5px 5px;
          display: block;
          background: #ffcd46;
          text-align: center;
          font-size: 1.2em;
          color: white;
          font-weight: 900;
          padding: 20px;
          text-decoration: none;
        }
      }
    }
  }

  .corp {
    flex: 1;
  }

  footer {
    .args {
      background: #fbfbfb;
      border-top: 1px solid #ededed;

      .container {
        max-width: 1170px;
        width: 100%;
        margin: 0px auto;
        display: flex;

        .arg {
          text-align: center;
          flex: 1;
          padding: 20px;
          padding-top: 50px;
  
          .img {
            display: flex;
            height: 60px;
            width: 60px;
            align-items: center;
            justify-content: center;
            margin: 0px auto;
            margin-bottom: 20px;
          }
        }
      }
    }

    .mentions {
      display: flex;
      background: #323232;
      font-size: 0.8em;
      
      .container {
        max-width: 1170px;
        width: 100%;
        margin: 0px auto;
        display: flex;
        padding: 60px 0px;

        .column {
          flex: 1;

          &.full {
            flex: 3;
          }

          .title {
            font-weight: 700;
            margin-bottom: 20px;
            color: white;
          }

          &> a + a {
            display: block;
            margin-top: 4px;
          }

          a {
            color: white;
            text-decoration: none;

            &:hover {
              color: #ffcd46;
            }
          }
        }
        
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .guest-layout {

    header {

      .menu {

        .popin {
          top: 100%;
          width: 100vw;
          left: 0px;
          border-radius: 0px;

          .tel {
            display: flex;
          }
        }
      }

      .tel {
        display: none;
      }

      .basket {

        .label {
          display: none;
        }

        .popin {
          top: 100%;
          width: 100vw;
          right: 0px;
          border-radius: 0px;

          .lines {
            border-left: none;
            border-right: none;
            border-radius: 0px;
            max-height: 400px;
            overflow: auto;
            // min-height: calc(100vh - 148px);
            // max-height: calc(100vh - 148px);
          }

          .go {
            border-radius: 0px;
            font-size: 1em;
          }
        }
      }
    }

    footer {
      .args {
        .container {
          flex-wrap: wrap;

          .arg {
            width: 50%;
          }
        }
      }

      .mentions {
        .container {
          flex-direction: column;
          padding: 20px;
          margin: 0px;

          &> * + * {
            margin-top: 30px;
          }

          .column {
            .title {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
}