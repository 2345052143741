.home-page {
  width: 1170px;
  max-width: 100%;
  margin: 40px auto;

  .cover {
    display: block;
    width: 1170px;
    max-width: 100%;
    margin: 40px auto;
    border-radius: 10px;
    height: auto;
    max-height: 400px;
    object-fit: cover;
  }

  .campaigns {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-columns: minmax(100px, auto);
    // grid-auto-rows: 200px;  // height of line
    grid-gap: 40px;
    width: 100%;
    max-width: 1170px;
    margin: 40px auto;

    .campaign {
      border-radius: 5px;
      text-decoration: none;
      color: inherit;
      border: 1px solid white;

      &:hover {
        border-color: #E9E9E9;

        .first {
          display: none;
        }

        .second {
          display: block;
        }
      }

      .first {
        display: block;
      }

      .second {
        display: none;
      }

      img {
        width: 100%;
        border-radius: 5px;
        margin-bottom: 10px;
        display: block;
      }

      .name {
        font-weight: bold;
        text-align: center;
        margin-bottom: 4px;
        display: block;
      }

      .price {
        font-size: 0.9em;
        text-align: center;
        display: block;
        padding-bottom: 10px;
      }
    }
  }
}

.success-page {
  padding: 50px 0px;
  width: 100%;
  margin: auto;
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .message {
    margin-left: 20px;
  }
}

@media screen and (max-width: 650px) {
  .home-page {
    margin: 0px;

    .cover {
      border-radius: 0px;
      margin-top: 0px;
    }

    .campaigns {
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      grid-gap: 20px;
    }
  }

  .success-page {
    flex-direction: column;

    .message {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}