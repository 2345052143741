.checkout-page {
  width: 100%;
  display: flex;

  &.empty {
    flex: 1;
    align-items: center;
    justify-content: center;
    color: grey;
    font-size: 2em;
  }

  .creating-loader {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    z-index: 99999;
  }

  .infos-mobile {
    display: none;
  }


  button {
    flex: 0 0 auto;
    padding: 8px 10px;
    font-size: 1em;
    font-weight: 700;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: white;
    background: #2f2f2f;
    border: 1px solid black;

    &:disabled {
      color: grey;
      background-color: #F9F9F9;
      cursor: not-allowed;
      border-color: grey;
    }
  }

  .mobile-coupon {
    display: none;
    padding-top: 20px;
    margin: 0px 20px;

    .text {
      flex: 1;

      &.blue {
        color: blue;
        cursor: pointer;
      }
    }

    .price {
      font-weight: bold;
    }

    .form {
      flex: 1;
      
      &> * + * {
        margin-top: 4px;
      }

      .box {
        display: flex;
      }

      label {
        display: block;
        margin-bottom: 2px;
        font-size: 0.8em;
      }

      input {
        flex: 1;
        border: 1px solid #ccc;
        border-radius: 4px 0px 0px 4px;
        padding: 10px 8px;
        font-size: 1em;
        border-right: none;
      }

      button {
        border-radius: 0px 4px 4px 0px;
      }
    }
  }

  &>.form {
    flex: 1;
    padding: 40px 20px;
    display: flex;
    justify-content: flex-end;

    .wrapper {
      max-width: 682px;
      width: 100%;

      &> * + * {
        margin-top: 20px;
        padding-top: 20px;
        //border-top : 1px solid #ececec;
      }

      .step {

        .title {
          font-weight: 700;
          font-size: 1.8em;
          margin: 6px 0px;
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          .text {
            flex: 1;
          }

          .suf {
            color: grey;
            font-size: 12px;
            display: flex;
            align-items: center;

            svg {
              width: 20px;
            }
          }
        }

        .box {
          display: flex;

          &>* + * {
            margin-left: 20px;
          }

          &>* {
            flex: 1;
          }
        }

        &> input + label, &> select + label, &> .box + label, input + .box, .input + .box, .box + .box {
          margin-top: 8px;
        }

        label {
          display: block;
          margin-bottom: 4px;
        }

        input, .input, select {
          border: 1px solid #ccc;
          border-radius: 4px;
          padding: 10px 8px;
          font-size: 1em;
          width: 100%;

          &:disabled {
            cursor: not-allowed;
          }
        }

        .error {
          color: red;
          font-size: 0.9em;
          padding: 5px 0px;
        }
        
        .tips {
          color: grey;
          font-size: 0.8em;
          margin-top: 3px;
        }

        button.right {
          border-radius: 0px 4px 4px 0px;
        }

        .ctas {
          margin-top: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .card-list {
            margin: 0px;
            padding: 0px;

            li {
              display: inline-block;
              background-image: url(/images/card-brands.svg);
              height: 25px;
              margin: 8px 25px 8px 0;
              overflow: hidden;
              text-indent: -500px;

              &.cardBrand-visa {
                width: 44px;
              }
              &.cardBrand-masterCard {
                  background-position: -48px 0;
                  width: 84px;
              }
              &.cardBrand-americanExpress {
                  background-position: -138px 0;
                  width: 52px;
              }
            }
          }
        }
      }
    }
  }

  .basket {
    padding: 40px 20px;
    width: 40%;
    margin-left: 20px;
    border-left: 1px solid rgb(237, 237, 237);
    background: rgb(251, 251, 251);

    .desktop-coupon {
      display: flex;
    }

    .wrapper {
      max-width: 468px;

      .prices {
        padding-bottom: 20px;

        .lines {

          &> * + * {
            padding-top: 20px;
          }

          .line {
            display: flex;
            align-items: center;
    
            img {
              width: 40px;
              margin-right: 20px;
              border-radius: 4px;
            }
    
            .text {
              flex: 1;
            }
    
            .price {
              font-weight: bold;
            }
    
            .remove {
              padding: 10px;
              border-radius: 4px;
              cursor: pointer;
    
              &:hover {
                background: #f9b3b3;
                color: red;
              }
            }
          }
        }
    
        .part {
          padding-top: 20px;
          display: flex;

          &.mobile-coupon {
            display: none;
          }
    
          .text {
            flex: 1;

            &.blue {
              color: blue;
              cursor: pointer;
            }
          }
    
          .price {
            font-weight: bold;
          }

          .form {
            flex: 1;
            
            &> * + * {
              margin-top: 4px;
            }

            .box {
              display: flex;
            }

            label {
              display: block;
              margin-bottom: 2px;
              font-size: 0.8em;
            }
    
            input {
              flex: 1;
              border: 1px solid #ccc;
              border-radius: 4px 0px 0px 4px;
              padding: 10px 8px;
              font-size: 1em;
              border-right: none;
            }

            button {
              border-radius: 0px 4px 4px 0px;
            }
          }
        }
      }

      .infos {
        font-size: 0.9em;

        .title {
          font-weight: bold;
          margin-bottom: 5px;
          margin-top: 20px;
        }

        .text {
          font-size: 0.9em;
        }
      }
    }
  }
}


@media screen and (max-width: 650px) {
  .checkout-page {
    flex-direction: column-reverse;

    .mobile-coupon {
      display: flex;
    }

    .infos-mobile {
      display: block;
      padding: 20px;

      .infos {
        font-size: 0.9em;

        .title {
          font-weight: bold;
          margin-bottom: 5px;
          margin-top: 20px;
        }

        .text {
          font-size: 0.9em;
        }
      }
    }

    &>.form {
      margin-bottom: 20px;
      padding: 20px;

      .wrapper {

        .step {

          .box {
            flex-direction: column;

            &> * + * {
              margin-left: 0px;
              margin-top: 8px;
            }
          }

          .ctas {
            flex-direction: column;
            align-items: center;

            button {
              width: 100%;
              margin-bottom: 10px;
            }

            &>div {
              font-size: 0.8em;
            }
          }
        }
      }
    }

    .basket {
      margin-left: 0px;
      width: 100%;
      padding: 20px;
      border-bottom: 1px solid #e7e7e7;

      .desktop-coupon {
        display: none !important;
      }

      .wrapper {
        .prices {
          box-shadow: none;
          padding-bottom: 0px;

          .part {

            &.mobile-coupon {
              display: flex;
            }
          }
        }
  
        .infos {
          display: none;
        }
      }      
    }
  }
}