.faq-page {
  max-width: 770px;
  width: 100%;
  padding: 0px 20px;
  margin: 40px auto;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    padding: 30px 0px;
  }

  .question {
    margin-bottom: 20px;

    .label {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
}