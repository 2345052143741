.collection-page {
  max-width: 1170px;
  width: 100%;

  .title {
    text-align: center;
    font-weight: 700;
    font-size: 1.2em;
    padding: 30px 0px;
  }
}