.campaign-page {
  .campaign {
    display: flex;
    max-width: 1170px;
    width: 100%;
    margin: 40px auto;

    .show-mobile {
      display: none;
    }

    .gallery {
      position: relative;
      flex: 1;

      .carousel {
        display: none;
      }

      .previews {
        position: absolute;
        display: flex;
        flex-direction: column;

        & > * + * {
          margin-top: 5px;
        }

        img {
          width: 48px;
          height: 57px;
          border-radius: 3px;
          border: 1px solid #ccc;
          cursor: pointer;

          &:hover {
            border-color: #ffcd46;
          }
        }
      }

      .show {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          max-width: 100%;
        }
      }
    }

    .informations {
      width: 470px;
      margin-left: 20px;

      .name {
        font-size: 1.6em;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .description {
        font-size: 0.9em;
        margin-bottom: 30px;
      }

      .form {
        background: #f6f6f9;
        border: 1px solid #edeff3;
        border-radius: 4px;

        .part {
          padding: 20px;

          label {
            display: block;
            margin-bottom: 4px;
            font-weight: 700;
            font-size: 0.9em;
          }

          select {
            margin-bottom: 0;
            width: 100%;
            position: relative;
            left: -1px;
            background-color: #fff;
            background-image: url(https://d1b2zzpxewkr9z.cloudfront.net/images/icons/double_caret.png);
            background-repeat: no-repeat;
            background-position: right 0.3rem top 50%;
            background-size: 14px 16px;
            border: 1px solid #d8dae0;
            border-radius: 2px;
            color: #0a0918;
            font-size: 17px;
            line-height: 19.6px;
            font-weight: 400;
            outline: 0;
            max-width: 100%;
            padding: 11px 14px;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }

          .previews {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
            grid-gap: 10px;
            margin-top: 10px;
            margin-bottom: 20px;

            .preview {
              border: 1px solid #d9d9d9;
              border-radius: 3px;
              cursor: pointer;
              max-width: 100%;

              &.active {
                border-color: #ffcd46;
              }
            }
          }

          .colors {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
            //grid-auto-columns: minmax(40px, auto);
            grid-gap: 10px;
            margin-top: 10px;
            margin-bottom: 20px;

            .color {
              background: #fff;
              border: 1px solid #d8dae0;
              padding: 3px;
              border-radius: 36px;
              cursor: pointer;
              height: 36px;
              width: 36px;
              transition: all 0.4s ease-out;
              display: flex;
              align-items: center;
              justify-content: center;

              &.active {
                border-color: #ffcd46;
              }

              & > div {
                box-shadow: inset 0 0 1px #d8dae0;
                overflow: hidden;
                border-radius: 15px;
                margin: auto;
                width: 28px;
                height: 28px;
              }
            }
          }

          .sizes {
            display: flex;
            flex-wrap: wrap;

            .size {
              border: 1px solid #edeff3;
              border-radius: 2px;
              text-align: center;
              vertical-align: middle;
              padding: 6px 10px;
              float: left;
              width: 49px;
              margin-right: 7px;
              background: #fff;
              font-size: 14px;
              font-weight: 400;
              cursor: pointer;
              margin-bottom: 5px;

              &.active {
                border-color: #ffcd46;
              }
            }
          }

          .size {
            position: relative;
            width: 60px; /* Largeur du conteneur */
            height: 60px; /* Hauteur du conteneur */
            border: 1px solid #ccc;
            border-radius: 5px;
            margin: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            background-color: #fff;
            cursor: pointer;
            transition: background-color 0.3s, opacity 0.3s;

            &.active {
              border-color: #ffcd46;
            }
          }

          .size.unavailable {
            background-color: #f9f9f9; /* Fond légèrement grisé */
            cursor: not-allowed; /* Curseur désactivé */
          }

          .size.unavailable .cross {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 2.5rem; /* Taille de la croix pour occuper le conteneur */
            color: #ccc; /* Couleur gris clair */
            font-weight: 300; /* Réduction de l'épaisseur */
            font-family: Arial, sans-serif; /* Une police fine et lisible */
            pointer-events: none; /* Empêche toute interaction avec la croix */
          }

          .size-label {
            font-size: 1rem;
            color: #333;
          }

          .size.active {
            border-color: #007bff; /* Indique une taille sélectionnée */
          }

          .size:hover:not(.unavailable) {
            background-color: #e9f5ff; /* Fond au survol si disponible */
          }

          .size-guide-cta {
            cursor: pointer;
            color: blue;
            font-size: 0.9em;

            &.show {
              svg {
                transform: rotate(90deg);
              }
            }

            svg {
              width: 10px;
              height: 10px;
              margin-left: 5px;
              transition-duration: 0.3s;
            }
          }

          .size-guide {
            background: white;
            border-top: 1px solid #edeff3;
            border-bottom: 1px solid #edeff3;
            margin: 0px -20px;
            padding: 5px 20px;

            table {
              width: 100%;

              tr {
                td {
                  &.top {
                    font-weight: 700;
                  }
                }
              }
            }
          }
        }

        .cta {
          display: flex;

          .price {
            font-size: 1.2em;
            width: 35%;
            background: white;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 700;
          }

          button {
            flex: 1;
            cursor: pointer;
            background: #ffcd46;
            border: none;
            font-size: 1.2em;
            padding: 12px 43px;
            color: #fff;
            font-weight: 500;
          }
        }

        .cards {
          display: none;
          align-items: center;
          justify-content: center;
          padding: 5px;

          .card-list {
            margin: 0px;
            padding: 0px;

            li {
              display: inline-block;
              background-image: url(/images/card-brands.svg);
              height: 25px;
              margin: 8px 25px 8px 0;
              overflow: hidden;
              text-indent: -500px;

              &.cardBrand-visa {
                width: 44px;
              }
              &.cardBrand-masterCard {
                background-position: -48px 0;
                width: 84px;
              }
              &.cardBrand-americanExpress {
                background-position: -138px 0;
                width: 52px;
              }
            }
          }
        }

        .infos {
          padding: 10px 10px;
          margin-left: 25%;
          font-size: 0.8em;

          .line {
            img {
              width: 13px;
              height: 10px;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }

  & > .show-mobile {
    display: none;
    padding: 20px;
  }

  .more {
    background: #f6f6f9;
    margin-top: 40px;
    border-bottom: 1px solid #e9e9e9;

    .container {
      max-width: 1170px;
      width: 100%;
      margin: 0px auto;

      .list {
        display: flex;
        justify-content: center;
        padding-top: 20px;

        & > * + * {
          margin-left: 20px;
        }

        .item {
          cursor: pointer;
          padding: 10px 20px;
          border-bottom: 1px solid #f6f6f9;
          border-radius: 4px 4px 0px 0px;

          &:hover,
          &.active {
            border-color: #ffcd46;
          }

          &:hover {
            background: #e9e9e9;
          }
        }
      }

      .tab {
        padding: 40px 0px;
        font-weight: 400;
        color: #3e4044;
      }
    }
  }
}

@media screen and (max-width: 1170px) {
  .campaign-page {
    .campaign {
      .gallery {
        .previews {
          left: 20px;
        }
      }

      .informations {
        .name {
          padding-right: 20px;
        }

        .description {
          padding-right: 20px;
        }

        .form {
          border-right: 0px;
          border-radius: 4px 0px 0px 4px;
        }
      }
    }

    .more {
      .container {
        .tab {
          padding: 20px;
        }
      }
    }
  }
}
@media screen and (max-width: 650px) {
  .campaign-page {
    .campaign {
      flex-direction: column;
      margin: 0px;

      .show-mobile {
        display: block;

        .name {
          text-align: center;
          font-size: 1.4em;
          font-weight: 700;
          margin-top: 20px;
          margin-bottom: 10px;
        }
      }

      .gallery {
        .previews {
          display: none;
        }

        .show {
          display: none;
        }

        .carousel {
          display: block;
          .carousel-indicators {
            li {
              background: #d9d9d9;
              border-radius: 100%;
              border: none;
              width: 10px;
              height: 10px;

              &.active {
                background: black;
              }
            }
          }

          .carousel-prev {
            background: url(https://wiltee.com/assets/img/arrow-left.png);
            background-size: 17px 32px;
            width: 17px;
            height: 32px;
          }

          .carousel-next {
            background: url(https://wiltee.com/assets/img/arrow-right.png);
            background-size: 17px 32px;
            width: 17px;
            height: 32px;
          }

          // .carousel-control-prev {
          //   .carousel-control-prev-icon {
          //     background: url("data:image/svg+xml;charset=utf-8,<svg xmlns='http://www.w3.org/2000/svg' fill='#000000' width='8' height='8' path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z' svg>")
          //   }
          // }
        }
      }

      .informations {
        width: 100%;
        margin-left: 0px;

        .name {
          display: none;
        }

        .description {
          display: none;
        }

        .form {
          border-left: 0px;
          border-right: 0px;
          border-radius: 0px;

          .cta {
            .price {
              display: none;
            }
          }

          .infos  {
            margin-left: 0%;
          }

          .cards {
            display: flex;
          }
        }
      }
    }

    & > .show-mobile {
      display: block;
    }

    .more {
      margin-top: 0px;

      .container {
        .list {
          & > * + * {
            margin-left: 0px;
          }

          .item {
            font-size: 0.8em;
            text-align: center;
          }
        }

        .tab {
          padding: 20px;
        }
      }
    }
  }
}
